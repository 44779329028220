import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import headerBG from '../assets/header_background.png'
import bdsm from '../assets/bdsm.png'
import blm from '../assets/blm.png'
import trans from '../assets/trans.png'
import poly from '../assets/poly.png'

export default {
  email,
  mobile,
  headerBG,
  bdsm,
  blm,
  poly,
  trans

};